import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { loginSuccess } from "./redux/actions/authActions";
import { connect } from "react-redux";
import "./App.scss";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const ForgotPassword = React.lazy(() =>
  import("./views/Pages/ForgotPassword/ForgotPassword")
);
const Login = React.lazy(() => import("./views/Pages/Login"));

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login_success: (payload) => dispatch(loginSuccess(payload)),
  };
};

class AppComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }

  componentDidMount() {
    const { login_success } = this.props;
    const userInfos = localStorage.getItem("@contabilizar-admin/user");
    if (userInfos) {
      login_success(JSON.parse(userInfos));
      return this.setState({ redirect: true });
    }
  }

  render() {
    const { redirect } = this.state;
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/forgot-password"
              name="Forgot Password"
              render={(props) => <ForgotPassword {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) =>
                !redirect ? <Login {...props} /> : <DefaultLayout {...props} />
              }
            />{" "}
          </Switch>{" "}
        </React.Suspense>
      </HashRouter>
    );
  }
}

const App = connect(mapStateToProps, mapDispatchToProps)(AppComponent);

export default App;
