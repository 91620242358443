import { LOGIN_SUCCESS, UPDATE_PROFILE } from "../constants";

export const loginSuccess = (payload) => {
  return {
    type: LOGIN_SUCCESS,
    payload,
  };
};

export const updateProfile = (payload) => {
  if (payload.username) {
    let infos = JSON.parse(localStorage.getItem("@contabilizar-admin/user"));
    infos = { ...infos, username: payload.username };
    localStorage.setItem("@contabilizar-admin/user", JSON.stringify(infos));
    return {
      type: UPDATE_PROFILE,
      payload: infos,
    };
  } else {
    return true;
  }
};

export const recoverPassword = (payload) => {
  return {};
};
