import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { NotificationContainer } from "react-notifications";

import App from "./App";
import { configureStore } from "./redux/store/configureStore";
import "react-notifications/lib/notifications.css";
import "./index.css";

export const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <>
      <App />
      <NotificationContainer />
    </>
  </Provider>,
  document.getElementById("root")
);
